<template>
  <b-card>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Sayfa Türü") }}</label>
          <v-select
            v-model="webSiteSayfaTurFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="webSiteSayfaTurOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
         <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t("Arama") }}</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
// Options
import StaticOptions from '@/common/options/StaticOptions'

export default {
  data() {
    return {
      webSiteSayfaTurOptions: StaticOptions.webSiteSayfaTurOptions,
    }
  },
  computed: {
    webSiteSayfaTurFilter: {
      get() {
        return this.$store.getters['sayfaYonetim/getSayfaFilter'].webSiteSayfaTur
      },
      set(value) {
        this.$store.commit('sayfaYonetim/setSayfaFilter', { key: 'webSiteSayfaTur', value })
      },
    },

    queryFilter: {
      get() {
         return this.$store.getters['sayfaYonetim/getSayfaFilter'].query
      },
      set(value) {
        this.$store.commit('sayfaYonetim/setSayfaFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style>
</style>
