<template>
  <div>
    <crud-button
      :onClickList="fetchSayfas"
      :onClickAddNew="addNew"
    />
    <sayfa-filters />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="sayfasTable"
        class="position-relative"
        :items="getSayfas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        empty-text="Kayıt bulunamadı"
      >
        <template #cell(webSiteSayfaTur)="data">
          {{ resolveTur(data.item.webSiteSayfaTur) }}
        </template>
        <template #cell(dtOlusTarih)="data">
          {{ formatDateToDMY(data.item.dtOlusTarih) }}
        </template>
        <template #cell(sDilKodu)="data">
          {{ data.item.sDilKodu == "tr" ? "Türkçe" : "English" }}
        </template>

        <!-- Column: User -->
        <template #cell(isActive)="data">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.id)"
          >
            {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </template>
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button
            size="sm"
            @click="
              $router.push({
                name: 'manage-website-sayfa-save',
                params: { id: data.item.id },
              })
            "
            variant="primary"
          >
            <span class="align-middle">{{ $t('Güncelle') }}</span>
          </b-button>

          <!-- Remove -->
          <b-button
            size="sm"
            class="ml-1 remove-button-mt-1"
            @click="remove(data.item.id)"
            variant="warning"
          >
            <span class="align-middle">{{ $t('Kaldır') }}</span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="toplamSayfa"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import useSayfas from './useSayfas';
import SayfaFilters from './SayfaFilters.vue';

export default {
  components: {
    SayfaFilters,
  },

  setup() {
    const {
      fetchSayfas,
      getSayfas,
      tableColumns,
      perPage,
      currentPage,
      toplamSayfa,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      sayfasTable,

      resolveTur,
      addNew,
      remove,
      deactivate,
      formatDateToDMY,
    } = useSayfas();

    return {
      fetchSayfas,
      getSayfas,
      tableColumns,
      perPage,
      currentPage,
      toplamSayfa,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      sayfasTable,

      resolveTur,
      addNew,
      remove,
      deactivate,
      formatDateToDMY,
    }
  },
}
</script>

<style scoped>
@media (max-width: 890px) {
  .remove-button-mt-1 {
    margin-top: 10px !important;
  }
}
</style>
