import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { formatDateToDMY } from '@core/utils/filter';

export default function useSayfas() {
  const sayfasTable = ref(null)
  
  const tableColumns = [
    { key: 'sBaslik', label: 'Başlık' },
    { key: 'sUrl', label: 'URL' },
    { key: 'webSiteSayfaTur', label: 'Tür' },
    { key: 'dtOlusTarih', label: 'Ekleme Tarihi', sortable: true },
    { key: 'sDilKodu', label: 'Dil' },
    { key: 'isActive', label: 'Aktif Durum', tdStyle: 'width:5px !important', thStyle: 'width:5px !important' },
    { key: 'actions', label: 'Aksiyon', class: 'text-right' },
  ]
  const perPage = ref(10)
  const toplamSayfa = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getSayfaLocalFilters.sortBy
    },
    set(value) {
      store.commit('setSayfaFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getSayfaLocalFilters.sortDesc
    },
    set(value) {
      store.commit('setSayfaFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = sayfasTable.value ? sayfasTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamSayfa.value,
    }
  })

  const refetchData = () => {
    sayfasTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  function fetchSayfas() {
    store.dispatch('sayfaYonetim/fetchSayfas', {})
  }

  const getSayfas = computed(() => {
    const sayfas = store.getters['sayfaYonetim/getSayfas']
    toplamSayfa.value = sayfas.length
    return sayfas
  })

  onMounted(fetchSayfas)

  function addNew() {
    this.$router.push({ name: 'manage-website-sayfa-save', params: { id: -1 } })
  }

  function remove(id) {
    AlertService.confirmDelete(this, () => {
      this.$store.dispatch('sayfaYonetim/removeSayfa', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            this.fetchSayfas();
          } else {
            AlertService.error(this, response.message)
          }
        });
    });
  }

  function deactivate(id) {
    store.dispatch('sayfaYonetim/toggleActiveSayfa', id)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
        } else {
          AlertService.error(this, response.message);
        }
      })
  }

  function resolveTur(tur) {
    return tur === 1 ? 'Blog' : 'Gizli Sayfa'
  }

  return {
    fetchSayfas,
    getSayfas,
    tableColumns,
    perPage,
    currentPage,
    toplamSayfa,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    sayfasTable,
    
    resolveTur,

    addNew,
    remove,
    deactivate,
    formatDateToDMY,
  }
}
